import AnimatedPage from "../components/AnimatedPage";
import HomePageContent from "../components/HomePageContent";
import React from "react";
import TeaserSection from "../components/TeaserSection";
import _ from "lodash";
import { graphql } from "gatsby";

const IndexPage = ({ data }) => {
  const { page, property } = data;
  const { title, description, featured } = page.frontmatter;

  return (
    <AnimatedPage>
      <HomePageContent
        title={title}
        description={description}
        featured={featured}
      />

      <TeaserSection
        elements={_.map(property.edges, (edge) => {
          const { frontmatter, fields } = edge.node;

          return {
            title: frontmatter.title,
            description: frontmatter.description,
            image: frontmatter.featured,
            to: fields.slug,
          };
        })}
      />
    </AnimatedPage>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    page: markdownRemark(fileAbsolutePath: { regex: "/home.md/" }) {
      frontmatter {
        title
        description
        featured {
          childImageSharp {
            gatsbyImageData(
              width: 1024
              layout: FULL_WIDTH
              formats: [AUTO, WEBP, AVIF]
              quality: 80
              placeholder: BLURRED
            )
          }
        }
      }
    }
    property: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: {
        fileAbsolutePath: { regex: "/(properties)/" }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            featured {
              childImageSharp {
                gatsbyImageData(
                  width: 1024
                  quality: 80
                  aspectRatio: 1.5
                  placeholder: BLURRED
                )
              }
            }
          }
          fields {
            slug
          }
          fileAbsolutePath
        }
      }
    }
  }
`;
