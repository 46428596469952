import * as React from "react";

import Card from "./Card";
import PropTypes from "prop-types";

const TeaserSection = ({ elements }) => {
  return (
    <section className="content section container grid-960">
      <div className="columns">
        {elements?.map(({ title, description, image, to }, index) => {
          return (
            <div className="column col-6 col-sm-12" key={index}>
              <Card
                title={title}
                description={description}
                image={image}
                to={to}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

TeaserSection.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      imgSrc: PropTypes.string,
      to: PropTypes.string,
    })
  ),
};

export default TeaserSection;
