import * as React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import PropTypes from "prop-types";
import SpringLink from "./SpringLink";

const Card = ({ title, description, image, to }) => {
  const gatsbyImageData = getImage(image);

  return (
    <div className="reveal">
      <div className="card">
        <div className="card-image">
          <SpringLink to={to}>
            <GatsbyImage image={gatsbyImageData} alt="" />
          </SpringLink>
        </div>

        <div className="card-header">
          <div className="card-title h2">{title}</div>
        </div>

        <div className="card-body">{description}</div>

        <div className="card-footer card-footer-narrow">
          <SpringLink to={to} className="btn btn-block btn-borderless btn-icon">
            <i className="ion-ios-arrow-round-forward btn-icon-before"></i>
            <span>Mehr erfahren</span>
            <i className="ion-ios-arrow-round-forward btn-icon-after"></i>
          </SpringLink>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Card;
